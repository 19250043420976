import { Box, useTheme, Grid, Button, Paper, InputBase } from "@mui/material";
import ResponsiveDialog from "components/responsiveDialog";
import EmployeeDocumentUploadForm from "../forms/employeeForms/employeeDocumentsUploadForm";
import { useEmployeeDocumentsList } from "api/hooks/humanResource";
import { useSelector } from "react-redux";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { requester } from "api/pesabackend";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import { URL } from "api/pesabackend";

export default function EmployeeDocumentTable({
  setIsSuccess,
  employeeId,
  setIsFailed,
}) {
  const theme = useTheme();

  const {employeeDocumentList, isLoadingEmployeeDocumentList,setNewEmployeeDocumentData} = useEmployeeDocumentsList(1,employeeId);

  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [clickedItem, setClickedItem] = useState('');

  const token = useSelector((state) => state.token);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleClickDeleteOpen = async (e) => {
    setClickedItem(e.target.value)
    setOpenDelete(true)
  };

  const handleClickDeleteClose = () => {
    setOpenDelete(false);
  };

  const handleClickDelete = async (e) => {
    const response = await requester.post(`/human_resource/employees/delete_document/${clickedItem}`);
    setIsSuccess(true)
    setNewEmployeeDocumentData(true)
    setOpenDelete(false);
  };

  

  return (
    <Box sx={{ width: "100%", padding: "40px", margin: "20px" }}>
      <h4>Employee Document List</h4>
      <Box p="1rem" m="1rem" borderRadius="0.5rem">
        <Grid container spacing={4} className="alignHorizantal">
          <Grid xs></Grid>
          <Grid xs></Grid>
          <Grid xs sx={{ textAlign: "right" }}>
            <Button variant="contained" onClick={handleClickOpen}>
              Upload Document
            </Button>
            <ResponsiveDialog
              dialogButtonName="Add Document "
              dialogHeading="Add a New Document"
              open={open}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              negativeResponse="Cancel"
              content={
                <Box
                  p="1rem"
                  borderRadius="0.5rem"
                  backgroundColor={theme.palette.background.alt}
                >
                  <EmployeeDocumentUploadForm
                    setOpen={setOpen}
                    setNewEmployeeDocumentData={setNewEmployeeDocumentData}
                    setIsSuccess={setIsSuccess}
                    setIsFailed={setIsFailed}
                    employeeId={employeeId}
                  />
                </Box>
              }
            ></ResponsiveDialog>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell style={{ minWidth: 50 }}>No.</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Date Uploaded</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 150 }}>File Name</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 150 }}>Descritpion</StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>Actions</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {employeeDocumentList.length === 0 ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  employeeDocumentList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <StyledTableCell>{index + 1}</StyledTableCell>
                          <StyledTableCell>
                            {moment(row.date_added).format("LL")}
                          </StyledTableCell>
                          <StyledTableCell>{row.file_name}</StyledTableCell>
                          <StyledTableCell>{row.description}</StyledTableCell>

                          <StyledTableCell style={{ textAlign: "center" }}>
                            <Button
                              value={row.id}
                              size="small"
                              href={`${URL}/${row.file_path}`}
                            >
                              Download
                            </Button>

                            <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => handleClickDeleteOpen(e)}
                            >
                              Delete
                            </Button>

                            <ResponsiveDialog
                              dialogHeading="Are you sure you want to delete?"
                              open={openDelete}
                              handleClose={handleClickDeleteClose}
                              negativeResponse="Cancel"
                              content={
                                <Box
                                  p="1rem"
                                  borderRadius="0.5rem"
                                  backgroundColor={theme.palette.background.alt}
                                >
                                  {
                                    <Button
                                      fullWidth
                                      type="submit"
                                      variant="contained"
                                      onClick={handleClickDelete}
                                      sx={{
                                        m: "1rem 0",
                                        p: "1rem",
                                      }}
                                    >
                                      Yes, Delete
                                    </Button>
                                  }
                                </Box>
                              }
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={employeeDocumentList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
}
