import {Box, Grid, Button, Paper, MenuItem, TextField, Breadcrumbs, Link, Typography, useMediaQuery} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSupplierList } from "api/hooks/inventory";
import { useItemPurchaseReportList } from "api/hooks/reports";
import { useSelector } from "react-redux";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import moment from "moment";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DownloadIcon from "@mui/icons-material/Download";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import { currencyFormatter, withoutSymbolFormatter } from "utilities/currencyFormatter";

export default function CustomerReturnReport({ setIsSuccess }) {
  const navigate = useNavigate();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const {
    itemPurchaseReportList,
    isLoadingItemPurchaseReportList,
    error,
    setFilter,
    setSupplier,
  } = useItemPurchaseReportList(1, business_branch_id);
  const { supplierList } = useSupplierList(1, business_branch_id);

  const handleChangeSupplierFilter = async (e) => {
    setSupplier(e.target.value);
  };

  const [showFiter, setShowFilter] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  const handleChangePeriodFilter = async (e) => {
    if (e.target.value === "THIS MONTH") {
      setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
      setEndDate(moment().endOf("month").format("YYYY-MM-DD"));
      setShowFilter(false);
    }
    if (e.target.value === "THIS WEEK") {
      setStartDate(moment().startOf("week").format("YYYY-MM-DD"));
      setEndDate(moment().endOf("week").format("YYYY-MM-DD"));
      setShowFilter(false);
    }
    if (e.target.value === "TODAY") {
      setStartDate(moment().format("YYYY-MM-DD"));
      setEndDate(moment().format("YYYY-MM-DD"));
      setShowFilter(false);
    }
    if (e.target.value === "CUSTOM") {
      setShowFilter(true);
    }
  };

  const handleClickFilter = () => {
    setFilter({ start: startDate, end: endDate });
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box>
      <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/reports")}
        >
          <AssessmentIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Reports
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <b>Customer Return Report</b>
        </Typography>
      </Breadcrumbs>
      <Box>
        <Box p="1rem" m="1rem" borderRadius="0.5rem">
          <Grid container spacing={4} className="alignHorizantal">
            <Grid xs={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // m: "4px",
                }}
              >
                <h4>REPORT PERIOD:</h4>

                <TextField
                  label="PERIOD"
                  select
                  defaultValue="THIS MONTH"
                  size="small"
                  onChange={(e) => {
                    handleChangePeriodFilter(e);
                  }}
                  sx={{
                    minWidth: 50,
                    maxWidth: 300,
                    m: "10px",
                    backgroundColor: "white",
                  }}
                >
                  <MenuItem value="TODAY">TODAY</MenuItem>
                  <MenuItem value="THIS WEEK">THIS WEEK</MenuItem>
                  <MenuItem value="THIS MONTH">THIS MONTH</MenuItem>
                  <MenuItem value="CUSTOM">CUSTOM</MenuItem>
                </TextField>
                {showFiter ? (
                  <>
                    <TextField
                      label="Start Date"
                      type="date"
                      defaultValue={startDate}
                      size="small"
                      onChange={(e) => setStartDate(e.target.value)}
                      sx={{
                        m: "10px",
                        backgroundColor: "white",
                      }}
                    />
                    <TextField
                      label="End Date"
                      type="date"
                      defaultValue={endDate}
                      size="small"
                      onChange={(e) => setEndDate(e.target.value)}
                      sx={{
                        m: "10px",
                        backgroundColor: "white",
                      }}
                    />
                  </>
                ) : null}
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    m: "9px",
                  }}
                  onClick={() => handleClickFilter()}
                >
                  FILTER
                </Button>
              </Box>
            </Grid>

            <Grid xs={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // m: "4px",
                }}
              >
                <h4>FILTER BY CUSTOMER:</h4>

                <TextField
                  label="CUSTOMERS"
                  select
                  defaultValue="all"
                  size="small"
                  onChange={(e) => {
                    handleChangeSupplierFilter(e);
                  }}
                  sx={{
                    minWidth: 50,
                    maxWidth: 300,
                    m: "10px",
                    backgroundColor: "white",
                  }}
                >
                  <MenuItem key={"DEFAULT"} value="all">
                    ALL
                  </MenuItem>
                  {supplierList.map((row, index) => {
                    return (
                      <MenuItem key={index} value={row.id}>
                        {row.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Box>
            </Grid>
            <Grid xs={2} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                onClick={() =>
                  navigate("/purchaseForm", { formType: "create" })
                }
                endIcon={<DownloadIcon />}
              >
                EXPORT
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Paper sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight: "100vh" }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                maxHeight="100vh"
              >
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Purchase Date</StyledTableCell>
                    <StyledTableCell>Item</StyledTableCell>
                    <StyledTableCell style={{ minWidth: 100 }}>
                      Description
                    </StyledTableCell>
                    <StyledTableCell>Purchase Quantity</StyledTableCell>
                    <StyledTableCell>Unit</StyledTableCell>
                    <StyledTableCell>Cost Price</StyledTableCell>
                    <StyledTableCell>Supplier</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {itemPurchaseReportList.length === 0 ? (
                    <StyledTableRow>
                      <StyledTableCell
                        colSpan={12}
                        style={{ textAlign: "center" }}
                      >
                        <h3>No Data found</h3>
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : (
                    itemPurchaseReportList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <StyledTableRow key={row.id}>
                            <StyledTableCell>
                              {moment(
                                row.related_product_purchase.purchase_date
                              ).format("LL")}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.related_product.product_name}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.related_product.description}
                            </StyledTableCell>
                            <StyledTableCell>{row.quantity_purchased}</StyledTableCell>
                            <StyledTableCell>
                              {
                                row.related_product_unit
                                  .related_business_product_unit.name
                              }
                            </StyledTableCell>
                            <StyledTableCell>{row.amount}</StyledTableCell>
                            <StyledTableCell>
                              {
                                row.related_product_purchase
                                  .related_supplier.name
                              }
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={itemPurchaseReportList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </Box>
    </Box>
  );
}
