import { Box,  Grid, Button, Paper, InputBase } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import DummyTable from "components/Tables/DummyTable";
import Chip from "@mui/material/Chip";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import moment from "moment";
import { currencyFormatter } from "utilities/currencyFormatter";

import { useGetInvoicesList } from "api/hooks/billing";


export default function Invoices () {
  
  const navigate = useNavigate();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const permissions = useSelector((state) => state.permissions);

  const {invoicesList, isLoadingInvoicesList, errorInvoicesList} = useGetInvoicesList(1, business_branch_id)

  const renderStatusPills = (status) => {
		switch (status) {
			case false:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ffebeb", fontSize:"10px", fontWeight:700 }} label="UNPAID" size="medium" color="warning" />;
			case true:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ebefff", fontSize:"10px", fontWeight:700 }} label="PAID" size="medium" color="primary" />;
			default:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ffeded", fontSize:"10px", fontWeight:700 }} label="CANCELLED" size="medium" color="danger" />;
		}
	};

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  return (
    <Box>
      <h4>Invoices</h4>
      <Box p="1rem" m="1rem" borderRadius="0.5rem">
        <Grid container spacing={4} className="alignHorizantal">
          <InputBase
            placeholder="Search by Invoice Number"
            // value={searchContent}
            // onChange={handleSearchInput}
            sx={{ ml: 1, flex: 1 }}
          />
          {/* <IconButton onClick={handleClickSearch}>
            <SearchIcon />
          </IconButton> */}

        {permissions.BCI === 1 && (
          <Button
            variant="contained"
            onClick={() => navigate("/invoiceForm", { formType: "create" })}
          >
          CREATE INVOICE
          </Button>
        )}
        </Grid>
      </Box>

      <Box>
        <Paper sx={{ width: "100%" }}>
        {isLoadingInvoicesList ? (
            <DummyTable />
        ) : (
          <>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell style={{ minWidth: 50 }}>No.</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 100 }}>Invoice No.</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 100 }}>Date Created</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Amount</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Customer</StyledTableCell>
                  {/* <StyledTableCell style={{ minWidth: 50 }}>Discount</StyledTableCell> */}
                  <StyledTableCell style={{ minWidth: 50 }}>Status</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Created By</StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center", minWidth: 150 }}>
                    Actions
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {invoicesList.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </TableCell>
                  </TableRow>
                ) : (
                  invoicesList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell>{index + 1}</StyledTableCell>
                          <StyledTableCell>{row.invoice_number}</StyledTableCell>
                          <StyledTableCell>{moment(row.date_created).format("LLL")}</StyledTableCell>
                          <StyledTableCell>{currencyFormatter(parseFloat(row.total_amount))}</StyledTableCell>
                          <StyledTableCell>{row.related_customer? row.related_customer.related_user.first_name+' '+row.related_customer.related_user.last_name : null}</StyledTableCell>
                          {/* <StyledTableCell>{row.total_discount}</StyledTableCell> */}
                          <StyledTableCell>{renderStatusPills(row.status)}</StyledTableCell>
                          <StyledTableCell>{`${row.created_by.first_name} ${row.created_by.last_name}`}</StyledTableCell>
                          <StyledTableCell
                            style={{ textAlign: "center", minWidth: 100 }}
                          >
                            <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => navigate("/invoiceDetails", { state: { id: row.id }})}
                            >
                              View Details
                            </Button>
                            <Button
                              value={row.id}
                              size="small"
                              // onClick={(e) => handleClickDeleteOpen(e)}
                            >
                              Delete Invoice
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={invoicesList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          </>
        )}
        </Paper>
      </Box>
    </Box>
  );
};