import React, {useEffect} from "react";
import { Box, Alert} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTab } from "state";
import ChartOfAccounts from "../tables/chartOfAccountsTable";
import ProfitAndLossTable from "../tables/profitAndLossTable";
import BalanceSheetMonthsTable from "../tables/balanceSheetMonthsTable";
import PaymentTypesTable from "../tables/paymentTypesTable";
import ExpenseTypesTable from "../tables/expenseTypesTable";
import CustomTab from "components/Tabs/Tab";

const AccountingTab = () => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(useSelector((state)=> state.currentTab['accountingTab']) );
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setCurrentTab({ name: "accountingTab", tab: newValue })); 
  };
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isFailed, setIsFailed] = React.useState(false);

  const tabComponents = [
    {Component: <ChartOfAccounts />, label: "CHART OF ACCOUNTS", "index": 0},
    {Component: <BalanceSheetMonthsTable />, label: "BALANCE SHEET", "index": 1},
    // {Component: <ProfitAndLossTable />, label: "PROFIT AND LOSS", "index": 2},
    {Component: <PaymentTypesTable />, label: "PAYMENT TYPES", "index": 3},
    {Component: <ExpenseTypesTable />, label: "EXPENSE TYPES", "index": 4},
  ] 

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSuccess(false);
      setIsFailed(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);

  return (
    <Box>
      {isSuccess ? (
        <Alert sx={{ m: "15px" }} variant="filled" severity="success">
          Operation was successful!
        </Alert>
      ) : isFailed ? (
        <Alert sx={{ m: "15px" }} severity="error">
          Operation was not successful!
        </Alert>
      ) : null}

      <CustomTab tabComponents={tabComponents} value={value} handleChange={handleChange}/>
    </Box>
  );
};

export default AccountingTab;
