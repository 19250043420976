import {Box, Grid, Button, Paper,InputBase} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { usePurchaseList, useSupplierList } from "api/hooks/inventory";
import { useSelector } from "react-redux";
import { useState } from "react";
import FixedSizeDialog from "components/fixedSizeDialog";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import moment from "moment";
import { inventoryApi } from "api/pesabackend";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";

export default function SupplierPayablesList({ setIsSuccess }) {
  const navigate = useNavigate();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const { purchaseList, isLoadingPurchaseList, setSearch, setSupplierFilter } =
    usePurchaseList(1, business_branch_id);
  const { supplierList } = useSupplierList(1, business_branch_id);

  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [viewData, setViewData] = useState({});
  const [searchContent, setSearchContent] = useState("");

  const handleClickEditOpen = async (e) => {
    const response = await inventoryApi.get(
      `/product_purchase/get/${e.target.value}`
    );
    if (response.status === 200) {
      navigate("/editPurchaseForm", { state: response.data.data });
    }
  };

  const handleClickEditClose = () => {
    setOpenEdit(false);
  };

  const handleClickDeleteOpen = async (e) => {
    const response = await inventoryApi.get(
      `/product_purchase/get/${e.target.value}`
    );
    if (response.status === 200) {
      setOpenDelete(true);
    }
  };

  const handleClickDeleteClose = () => {
    setOpenDelete(false);
  };

  // const handleClickDelete = async () => {
  // const response = await inventoryApi.post(`/product_purchase/delete/${clickedItem}`);
  // setIsSuccess(true)
  // setNewPurchaseData(true)
  // setOpenDelete(false);
  // };

  const handleClickView = async (e) => {
    const response = await inventoryApi.get(
      `/product_purchase/get_form/${e.target.value}`
    );
    if (response.status === 200) {
      // navigate("/purchaseViewForm", { state: response.data.data });
      setViewData(response.data.data);
      setOpenView(true);
    }
  };

  const handleClickViewClose = async (e) => {
    setOpenView(false);
  };

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value);
  };

  const handleClickSearch = async () => {
    setSearch(searchContent);
  };

  const handleChangeFilter = async (e) => {
    setSupplierFilter(e.target.value);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box>
      <h4>Customer Recievables List</h4>
      <Box p="1rem" m="1rem" borderRadius="0.5rem">
        <Grid container spacing={4} className="alignHorizantal">
          <Grid xs={4}>
            <Paper
              sx={{
                my: "10px",
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                minWidth: 170,
                maxWidth: 300,
              }}
            >
              <InputBase
                placeholder="Search Supplier Name"
                value={searchContent}
                onChange={handleSearchInput}
                sx={{ ml: 1, flex: 1 }}
              />
              <IconButton onClick={handleClickSearch}>
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>

          <Grid xs={4}></Grid>
          <Grid xs={4} sx={{ textAlign: "right" }}></Grid>
        </Grid>
      </Box>

      <Box>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell style={{ minWidth: 50 }}>No.</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 150 }}>Supplier Name</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Current Outstanding Balance</StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center", minWidth: 150 }}>
                    Actions
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {purchaseList.length === 0 ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  purchaseList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell>{index + 1}</StyledTableCell>
                          <StyledTableCell>
                            {moment(row.date_added).format("LL")}
                          </StyledTableCell>
                          <StyledTableCell>{row.invoice_number}</StyledTableCell>
                          <StyledTableCell
                            style={{ textAlign: "center", minWidth: 150 }}
                          >
                            <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => handleClickView(e)}
                            >
                              View
                            </Button>
                          </StyledTableCell>
                          <FixedSizeDialog
                            dialogHeading="View Purchase Order"
                            open={openView}
                            handleClose={handleClickViewClose}
                            negativeResponse="Cancel"
                            maxWidth={"md"}
                            scroll={"body"}
                            content={<Box p="1rem" borderRadius="0.5rem"></Box>}
                          />
                        </StyledTableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={purchaseList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
}
