import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLogin } from "state";
import axios from "axios";
import { URL } from "api/pesabackend";
import LoadButton from "components/loadingButton";

const loginSchema = yup.object().shape({
  username: yup.string().required("required"),
  password: yup.string().required("required"),
});

const initialValuesLogin = {
  username: "",
  password: "",
};

const Form = () => {
  const [pageType, setPageType] = useState("login");
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isLogin = pageType === "login";
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [inCorrectLogin, setInCorrectLogin] = useState(false)

  const login = async (values, onSubmitProps) => {
    setLoadingLogin(true)
    try{
      const response = await axios.post(`${URL}/accounts/login`, values);
    
      onSubmitProps.resetForm();
      if (response.status === 200) {
        dispatch(
          setLogin({
            user: response.data.data.user_details,
            token: response.data.data.token.access,
            refresh: response.data.data.token.refresh,
            business_id: response.data.data.user_details.related_business,
            business_branch_id: response.data.data.user_details.related_business_branch,
            permissions: response.data.data.permissions.permissions,
            modules: response.data.data.permissions.modules
          })
        );
        sessionStorage.setItem('access_token', response.data.data.token.access)
        sessionStorage.setItem('refresh_token', response.data.data.token.refresh)
        setLoadingLogin(false)
        navigate("/dashboards");
      }
    }catch(e){
      setLoadingLogin(false);
      setInCorrectLogin(true);
      console.log(e)
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    await login(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesLogin}
      validationSchema={loginSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          {inCorrectLogin && (<Typography variant="subtitle1" color="red"> Invalid Username or Password. Please try again</Typography>)}
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >

            <TextField
              label="Username"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.username}
              name="username"
              error={Boolean(touched.username) && Boolean(errors.username)}
              helperText={touched.email && errors.username}
              sx={{ gridColumn: "span 4" }}
            />
            <TextField
              label="Password"
              type="password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              name="password"
              error={Boolean(touched.password) && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              sx={{ gridColumn: "span 4" }}
            />
          </Box>
          <Box>
            {!loadingLogin ? (
              <Button
                fullWidth
                type="submit"
                sx={{
                  m: "2rem 0",
                  p: "1rem",
                  backgroundColor: palette.background.secondary,
                  color: palette.secondary.white,
                  "&:hover": { color: palette.secondary.white, backgroundColor: palette.secondary.main},
                }}
              >
                LOGIN
              </Button>
            ) : (
              <LoadButton name={"LOGIN"} loading={loadingLogin} />
            )}
            
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default Form;
