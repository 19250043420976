import * as React from "react";
import { Box, Paper, MenuItem, Grid, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import BasicCard from "components/Cards/Card";
// import DoughnutChart from "components/Graphs/DoughnutChart";
// import DummyTable from "components/Tables/DummyTable";
// import { AddBoxOutlined } from "@mui/icons-material";
import LineChart from "components/Graphs/LineChart";
import { useSalesDashboard } from "api/hooks/dashboards";
import { useYearsList } from "api/hooks/inventory";
import { useState } from "react";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { currencyFormatter } from "utilities/currencyFormatter";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FEF0DE",
    color: theme.palette.common.black,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function SalesDashboard() {
  
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const {salesDashboard, isLoadingSalesDashboard, error, setPeriodType, setPeriod} = useSalesDashboard(business_branch_id);
  const {yearList} = useYearsList();
  const [periodSelect, setPeriodSelect] = useState("DAY");
  const [quarterYear, setQuarterYear] = useState(moment().format("YYYY"))

  const handlePeriodTypeSelect = (e) => {
    setPeriodSelect(e.target.value);
    setPeriodType(e.target.value);
  };

  
  const totalNumberOfSales = salesDashboard ? salesDashboard.invoice_count : 0;
  const invoiceAmount = salesDashboard.invoice_aggregation ? salesDashboard.invoice_aggregation.invoice_amount : 0;
  const highestInvoice = salesDashboard.invoice_aggregation ? salesDashboard.invoice_aggregation.max_invoice : 0;
  const averageInvoiceSale = salesDashboard.invoice_aggregation ? salesDashboard.invoice_aggregation.average_invoice : 0;
  // const highestReciept = salesDashboard.reciept_aggregation ? salesDashboard.reciept_aggregation.max_receipt : 0;
  const recieptAmount = salesDashboard.reciept_aggregation ? salesDashboard.reciept_aggregation.reciept_amount : 0;
  const salesInYear = salesDashboard ? salesDashboard.year_sales_record: [];
  const salesList = salesDashboard.top_sales ? salesDashboard.top_sales: [];
  const salesPeopleList = salesDashboard.top_sales_people ? salesDashboard.top_sales_people: [];
  const customersList = salesDashboard.top_customers ? salesDashboard.top_customers: [];


  return (
      <Box sx={{ height: "100%", width: "100%" }}>
        <Box p="1rem" m="1rem" borderRadius="0.5rem">
          <Grid container spacing={4} className="alignHorizantal">
            <Grid xs={4}>
              <h1>Sales Dashboard</h1>
            </Grid>

            <Grid xs={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  m: "4px",
                }}
              >
                <Paper
                  sx={{
                    m: "10px",
                    display: "flex",
                    alignItems: "center",
                    minWidth: 130,
                    maxWidth: 200,
                  }}
                >
                  <TextField
                    label="SELECT PERIOD"
                    select
                    defaultValue="DAY"
                    size="small"
                    onChange={(e) => {
                      handlePeriodTypeSelect(e);
                    }}
                    sx={{
                      minWidth: 50,
                      width: "100%",
                      // m: "10px",
                      // backgroundColor: "white",
                    }}
                  >
                    <MenuItem value="DAY"> DAY</MenuItem>
                    <MenuItem value="WEEK"> WEEK</MenuItem>
                    <MenuItem value="MONTH"> MONTH</MenuItem>
                    <MenuItem value="QUARTER"> QUARTER</MenuItem>
                    <MenuItem value="YEAR"> YEAR</MenuItem>
                  </TextField>
                </Paper>

                {periodSelect === "DAY" ? (
                  <Paper
                    sx={{
                      m: "10px",
                      display: "flex",
                      alignItems: "center",
                      minWidth: 130,
                      maxWidth: 200,
                    }}
                  >
                    <TextField
                      label="Period"
                      size="small"
                      defaultValue={moment().format("YYYY-MM-DD")}
                      onChange={(e) => setPeriod(e.target.value)}
                      type="date"
                      sx={{
                        minWidth: 50,
                        width: "100%",
                        // m: "10px",
                        // backgroundColor: "white",
                      }}
                    />
                  </Paper>
                ) : periodSelect === "WEEK" ? (
                  <Paper
                    sx={{
                      m: "10px",
                      display: "flex",
                      alignItems: "center",
                      minWidth: 130,
                      maxWidth: 200,
                    }}
                  >
                    <TextField
                      label="Period"
                      size="small"
                      onChange={(e) => setPeriod(e.target.value)}
                      type="week"
                      sx={{
                        minWidth: 50,
                        width: "100%",
                        // m: "10px",
                        // backgroundColor: "white",
                      }}
                    />
                  </Paper>
                ) : periodSelect === "MONTH" ? (
                  <Paper
                    sx={{
                      m: "10px",
                      display: "flex",
                      alignItems: "center",
                      minWidth: 130,
                      maxWidth: 200,
                    }}
                  >
                    <TextField
                      label="Period"
                      defaultValue={moment().format("YYYY-MM")}
                      size="small"
                      onChange={(e) => setPeriod(e.target.value)}
                      type="month"
                      sx={{
                        minWidth: 50,
                        width: "100%",
                        // m: "10px",
                        // backgroundColor: "white",
                      }}
                    />
                  </Paper>
                ) : periodSelect === "YEAR" ? (
                  <Paper
                    sx={{
                      m: "10px",
                      display: "flex",
                      alignItems: "center",
                      minWidth: 130,
                      maxWidth: 200,
                    }}
                  >
                    <TextField
                      label="Period"
                      size="small"
                      select
                      onChange={(e) => setPeriod(e.target.value)}
                      sx={{
                        minWidth: 50,
                        width: "100%",
                        // m: "10px",
                        // backgroundColor: "white",
                      }}
                    >
                      {yearList.map((year, index)=>{
                        return(
                          <MenuItem value={year.year}>{year.year}</MenuItem>
                        )
                      })}
                    </TextField>
                  </Paper>
                ) : periodSelect === "QUARTER" ? (
                  <>
                    <Paper
                      sx={{
                        m: "10px",
                        display: "flex",
                        alignItems: "center",
                        minWidth: 100,
                        maxWidth: 130,
                      }}
                    >
                      <TextField
                        label="Period"
                        size="small"
                        select
                        onChange={(e) => setPeriod(quarterYear+'-'+e.target.value)}
                        sx={{
                          minWidth: 50,
                          width: "100%",
                          // m: "10px",
                          // backgroundColor: "white",
                        }}
                      >
                        <MenuItem value="Q1"> Q1</MenuItem>
                        <MenuItem value="Q2"> Q2</MenuItem>
                        <MenuItem value="Q3"> Q3</MenuItem>
                        <MenuItem value="Q4"> Q4</MenuItem>
                      </TextField>
                    </Paper>
                    <Paper
                      sx={{
                        m: "10px",
                        display: "flex",
                        alignItems: "center",
                        minWidth: 100,
                        maxWidth: 130,
                      }}
                    >
                      <TextField
                        label="Year"
                        size="small"
                        select
                        defaultValue={moment().format("YYYY")}
                        onChange={(e) => setQuarterYear(e.target.value)}
                        sx={{
                          minWidth: 50,
                          width: "100%",
                          // m: "10px",
                          // backgroundColor: "white",
                        }}
                      >
                        {yearList.map((year, index)=>{
                          return(
                            <MenuItem value={year.year}>{year.year}</MenuItem>
                          )
                        })}
                      </TextField>
                    </Paper>
                  </>
                ) : null}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <BasicCard name={"Orders Made"} value={totalNumberOfSales} />
          <BasicCard name={"Total Invoice Amount"} value={currencyFormatter(parseFloat(invoiceAmount))} />
          <BasicCard name={"Total Cash Received"} value={currencyFormatter(parseFloat(recieptAmount))} />
          <BasicCard name={"Highest Recorded Sale"} value={currencyFormatter(parseFloat(highestInvoice))} />
          <BasicCard name={"Average Order"} value={currencyFormatter(parseFloat(averageInvoiceSale))} />
        </Box>

        <Box sx={{ marginTop: "30px" }}>
          <Paper
            sx={{
              padding: 3,
              marginY: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ flex: 8, marginX: 6 }}>
              <h3>Monthly Sales</h3>
              <LineChart
                label={[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "Septmeber",
                  "October",
                  "November",
                  "December",
                ]}
                data={[
                  {
                    label: "Sales for 2024",
                    data: salesInYear,
                    fill: false,
                    borderColor: "rgb(75, 192, 192)",
                    tension: 0.1,
                  },
                ]}
              />
            </Box>
          </Paper>
        </Box>

        <Box sx={{ marginTop: "30px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Paper sx={{ padding: 4, marginX: 3, flex: 2 }}>
              <h3>Top Sales Orders</h3>
              <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">
                        Invoice Number
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Sales Person
                      </StyledTableCell>
                      <StyledTableCell align="left">Amount</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {salesList.length === 0 ? (
                      <StyledTableRow>
                        <StyledTableCell
                          colSpan={3}
                          style={{ textAlign: "center" }}
                        >
                          <h3>No Data found</h3>
                        </StyledTableCell>
                      </StyledTableRow>
                    ) : (
                      salesList.map((row, index) => {
                        return (
                          <StyledTableRow key={row.id}>
                            <StyledTableCell align="left">
                              {row.invoice_number}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.sales_person}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {currencyFormatter(parseFloat(row.total_amount))} 
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Paper sx={{ padding: 4, marginX: 3, flex: 2 }}>
              <h3>Top Sales People</h3>
              <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">Employee</StyledTableCell>
                      <StyledTableCell align="left">
                        Orders Made
                      </StyledTableCell>
                      <StyledTableCell align="left">Amount</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {salesPeopleList.length === 0 ? (
                      <StyledTableRow>
                        <StyledTableCell
                          colSpan={3}
                          style={{ textAlign: "center" }}
                        >
                          <h3>No Data found</h3>
                        </StyledTableCell>
                      </StyledTableRow>
                    ) : (
                      salesPeopleList.map((row, index) => {
                        return (
                          <StyledTableRow key={row.id}>
                            <StyledTableCell align="left">
                              {row.first_name} {row.last_name}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.invoices_created}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {currencyFormatter(parseFloat(row.total_invoice_amount))} 
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Box>
        <Box sx={{ marginTop: "30px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Paper sx={{ padding: 4, marginX: 3, flex: 2 }}>
              <h3>Top Customers</h3>
              <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">
                        Customer
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Orders Made
                      </StyledTableCell>
                      <StyledTableCell align="left">Amount</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customersList.length === 0 ? (
                      <StyledTableRow>
                        <StyledTableCell
                          colSpan={3}
                          style={{ textAlign: "center" }}
                        >
                          <h3>No Data found</h3>
                        </StyledTableCell>
                      </StyledTableRow>
                    ) : (
                      customersList.map((row, index) => {
                        return (
                          <StyledTableRow key={row.id}>
                            <StyledTableCell align="left">
                              {row.customer_name}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.invoices_created}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {currencyFormatter(parseFloat(row.total_invoice_amount))} 
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Paper sx={{ padding: 4, marginX: 3, flex: 2 }}>
              {/* <h3>Top Sales People</h3>
              <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">Employee</StyledTableCell>
                      <StyledTableCell align="left">
                        Orders Made
                      </StyledTableCell>
                      <StyledTableCell align="left">Amount</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {salesPeopleList.length === 0 ? (
                      <StyledTableRow>
                        <StyledTableCell
                          colSpan={3}
                          style={{ textAlign: "center" }}
                        >
                          <h3>No Data found</h3>
                        </StyledTableCell>
                      </StyledTableRow>
                    ) : (
                      salesPeopleList.map((row, index) => {
                        return (
                          <StyledTableRow key={row.id}>
                            <StyledTableCell align="left">
                              {row.first_name} {row.last_name}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.invoices_created}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.total_invoice_amount}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer> */}
            </Paper>
          </Box>
        </Box>
      </Box>
  );
}
