import {
  Box,
  Grid,
  Button,
  Paper,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import FixedSizeDialog from "components/fixedSizeDialog";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import moment from "moment";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import UploadCreateForm from "../forms/createUploadForm";
import DownloadIcon from "@mui/icons-material/Download";
import { Upload } from "@mui/icons-material";
import { useGetProductUploadList } from "api/hooks/uploads";
import { URL, requester } from "api/pesabackend";
import axios from "axios";

export default function ProductUploadsTable({ setIsSuccess, setIsFailed }) {
  const navigate = useNavigate();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const {productUploadList, setNewProductUploadData} = useGetProductUploadList(business_branch_id);
  const token = useSelector((state) => state.token);
  const [open, setOpen] = useState(false);
  const [showFiter, setShowFilter] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  const saveUpload = async (values, onSubmitProps) => {

    const savedItemResponse = await axios.post(`${URL}/uploads/products`, values,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    
    );

    if (savedItemResponse.data.status === 201) {
      setOpen(false);
      setNewProductUploadData(true);
      onSubmitProps.resetForm();
      setIsSuccess(true);
    }

    if (savedItemResponse.data.status === 400) {
      setIsFailed(true);
    }
  };

  const handleClickDownloadTemplate = async ()=>{
    const _response = await requester.get(`/uploads/initial_stock/generate?business_branch_id=${business_branch_id}&sample=0`);
    if (_response.status === 200) {
      const a = document.createElement("a");
      a.href = `${URL}/${_response.data.data.file_url}`;
      a.download = _response.data.data.name;
      a.click();
    } 
  }

  const handleClickUploadOpen = async (e) => {
    setOpen(true);
  };

  const handleClickUploadClose = async (e) => {
    setOpen(false);
  };


  const handleClickView = async (file_data) => {
   navigate("/productUploadProgress", {state: file_data });
  };


  // const handleChangeFilter = async (e) => {
  //   if (e.target.value === "THIS MONTH") {
  //     setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
  //     setEndDate(moment().endOf("month").format("YYYY-MM-DD"));
  //     setShowFilter(false);
  //   }
  //   if (e.target.value === "CUSTOM") {
  //     setShowFilter(true);
  //   }
  // };

  // const handleClickFilter = () => {
  //   setFilter({ start: startDate, end: endDate });
  // };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box>
      <Typography variant="h3">Uploaded Products List</Typography>
      <Box p="1rem" m="1rem" borderRadius="0.5rem">
        <Grid container spacing={4} className="alignHorizantal">
          <Grid xs={3}>
            {/* <Paper
              sx={{
                my: "10px",
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                minWidth: 170,
                maxWidth: 300,
              }}
            >
              <InputBase
                placeholder="Search File Name"
                value={searchContent}
                onChange={handleSearchInput}
                sx={{ ml: 1, flex: 1 }}
              />
              <IconButton onClick={handleClickSearch}>
                <SearchIcon />
              </IconButton>
            </Paper> */}
          </Grid>

          <Grid xs={6}>
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                // m: "4px",
              }}
            >
              <h4>FILTER BY PERIOD:</h4>
              <Paper
                sx={{
                  m: "10px",
                  display: "flex",
                  alignItems: "center",
                  minWidth: 130,
                  maxWidth: 200,
                }}
              >
                <TextField
                  label="PERIOD"
                  select
                  defaultValue="THIS MONTH"
                  size="small"
                  onChange={(e) => {
                    handleChangeFilter(e);
                  }}
                  sx={{
                    minWidth: 50,
                    width: "100%",
                  }}
                >
                  <MenuItem value="THIS MONTH">THIS MONTH</MenuItem>
                  <MenuItem value="CUSTOM">CUSTOM</MenuItem>
                </TextField>
              </Paper>

              {showFiter ? (
                <>
                  <Paper
                    sx={{
                      m: "10px",
                      display: "flex",
                      alignItems: "center",
                      minWidth: 130,
                      maxWidth: 200,
                    }}
                  >
                    <TextField
                      label="Start Date"
                      type="date"
                      defaultValue={startDate}
                      size="small"
                      onChange={(e) => setStartDate(e.target.value)}
                      // sx={{
                      //   m: "10px",
                      //   backgroundColor: "white",
                      // }}
                      inputProps={{
                        max: endDate,
                      }}
                    />
                  </Paper>

                  <Paper
                    sx={{
                      m: "10px",
                      display: "flex",
                      alignItems: "center",
                      minWidth: 130,
                      maxWidth: 200,
                    }}
                  >
                    <TextField
                      label="End Date"
                      type="date"
                      defaultValue={endDate}
                      size="small"
                      onChange={(e) => setEndDate(e.target.value)}
                      // sx={{
                      //   m: "10px",
                      //   backgroundColor: "white",
                      // }}
                      inputProps={{
                        min: startDate,
                      }}
                    />
                  </Paper>
                </>
              ) : null}
              <Button
                variant="contained"
                size="small"
                sx={{
                  m: "9px",
                }}
                onClick={() => handleClickFilter()}
              >
                FILTER
              </Button>
            </Box> */}
          </Grid>
          <Grid xs={3} sx={{ textAlign: "right" }}>
            <Button variant="contained" endIcon={<DownloadIcon/>} onClick={() => handleClickDownloadTemplate()}>
              DOWLOAD TEMPLATE
            </Button>
            <Button sx={{ mx: "5px" }} endIcon={<Upload/>} variant="contained" onClick={() => handleClickUploadOpen()}>
              UPLOAD FILE
            </Button>
            <FixedSizeDialog
              dialogHeading="Upload File Form"
              open={open}
              handleClose={handleClickUploadClose}
              negativeResponse="Cancel"
              maxWidth={"md"}
              scroll={"body"}
              content={
                <Box
                  p="1rem"
                  borderRadius="0.5rem"
                  // backgroundColor={theme.palette.background.alt}
                >
                  <UploadCreateForm setOpen={setOpen}  saveItem={saveUpload} />
                </Box>
              }
            />
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell style={{ minWidth: 50 }}>
                    Date Uploaded
                  </StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>
                    File Status
                  </StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>
                    Number of Records
                  </StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>
                    Uploaded By
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ textAlign: "center", minWidth: 150 }}
                  >
                    Actions
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {productUploadList.length === 0 ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  productUploadList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell>
                            {moment(row.date_added).format("LLL")}
                          </StyledTableCell>
                          <StyledTableCell>{row.processed_status ? "PROCESSED" :  "NOT PROCESSED"}</StyledTableCell>
                          <StyledTableCell>{row.number_of_rows}</StyledTableCell>
                          <StyledTableCell>
                            {row.uploaded_by.first_name} {row.uploaded_by.last_name}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ textAlign: "center", minWidth: 150 }}
                          >
                            <Button
                              value={row.id}
                              size="small"
                              onClick={() => handleClickView(row)}
                            >
                              View Details
                            </Button>
                            {/* <Button
                              value={row.id}
                              size="small"
                              href={`http://localhost:8000/${row.uploaded_file}`}
                            >
                              Download
                            </Button> */}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={productUploadList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
}
