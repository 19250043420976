import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useSelector } from "react-redux";
import DashboardIcon from '@mui/icons-material/Dashboard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import TuneIcon from "@mui/icons-material/Tune";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import BadgeIcon from "@mui/icons-material/Badge";
import InventoryIcon from "@mui/icons-material/Inventory";
import StoreIcon from "@mui/icons-material/Store";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import PeopleIcon from '@mui/icons-material/People';
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import FolderIcon from '@mui/icons-material/Folder';
import BusinessIcon from "@mui/icons-material/Business";


const Item = ({ title, to, icon, selected, setSelected }) => {
  return (
        <MenuItem
            active={selected === title}
            style={{
                color: "#FFF",
                marginBottom: "10px",
            }}
            onClick={() => setSelected(title)}
            icon={icon}
        >
            <Typography sx={{ fontSize: { xs: "14px", md: "16px" } }} fontWeight={600}>
                {title}
            </Typography>
            <Link to={to} />
        </MenuItem>
	);
    };

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selected, setSelected] = useState("Dashboard");
  const modules = useSelector((state) => state.modules);
  

  return (
    <Box
        sx={{
            position: "sticky",
            display: "flex",
            height: "100vh",
            flex: 1,
            top: 0,
            bottom: 0,
            // zIndex: 10000,
            "& .pro-sidebar-inner": {
                background: `#108ED4 !important`,
            },
            "& .pro-icon-wrapper": {
                backgroundColor: "transparent !important",
            },
            "& .pro-inner-item": {
                padding: { xs: "5px 15px 5px 10px !important", sm: "5px 35px 5px 20px !important" },
            },
            "& .pro-menu-item": {
                color: "#FFF !important",
            },
            "& .pro-inner-item:hover": {
                color: "#FCB200 !important",
            },
            "& .pro-menu-item.active": {
                color: "#FCB200 !important",
            },
            borderRight: "1px solid #dfdede;",
        }}
    >
        <ProSidebar collapsed={isCollapsed}>
            <Menu iconShape="square">
                {/* LOGO AND MENU ICON */}
                <MenuItem
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                    style={{
                        margin: "10px 0 20px 0",
                        color: "darkblue",
                    }}
                >
                    {!isCollapsed && (
                        <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                            <Typography variant="h4" color={"#FFF"}>
                                JoliLabs
                            </Typography>
                            <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                <MenuOutlinedIcon sx={{ color: "darkblue" }} />
                            </IconButton>
                        </Box>
                    )}
                </MenuItem>

                <Box paddingLeft={isCollapsed ? undefined : "1%"}>
                    <Item title="Dashboard" to="/dashboards" icon={<DashboardIcon />} selected={selected} setSelected={setSelected} />
                    
                    {modules.billing === 1 && (
                        <Item title="Sales" to="/billing" icon={<StoreIcon />} selected={selected} setSelected={setSelected} />
                    )}

                    {modules.inventory === 1 && (
                        <>
                        
                        <Item title="Purchases" to="/purchase" icon={<ReceiptIcon />} selected={selected} setSelected={setSelected} />

                        <Item title="Suppliers" to="/suppliers" icon={<LocalShippingIcon />} selected={selected} setSelected={setSelected} />

                        <Item title="Inventory" to="/inventory" icon={<InventoryIcon />} selected={selected} setSelected={setSelected} />

                        <Item title="Stock Transfers" to="/transfers" icon={<AssignmentReturnIcon />} selected={selected} setSelected={setSelected} />

                        <Item title="Setup Inventory" to="/inventorySetup" icon={<FolderIcon />} selected={selected} setSelected={setSelected} />
                        </>
                    )}
                    
                    {modules.customers === 1 && (
                        <Item title="Customers" to="/customer" icon={<PeopleIcon />} selected={selected} setSelected={setSelected} />
                    )}

                    {modules.accounting === 1 && (
                        <Item title="Accounting" to="/accounting" icon={<AccountBalanceIcon />} selected={selected} setSelected={setSelected} />
                    )}

                    {modules.human_resource === 1 && (
                        <Item title="Human Resource" to="/humanResource" icon={<BadgeIcon />} selected={selected} setSelected={setSelected} />
                    )}

                    {modules.uploads === 1 && (
                        <Item title="File Uploads" to="/fileUploads" icon={<DriveFolderUploadIcon />} selected={selected} setSelected={setSelected} />
                    )}

                    {modules.admin === 1 && (
                        <>
                        <Item title="Reporting" to="/reports" icon={<AssessmentIcon />} selected={selected} setSelected={setSelected} />

                        <Item title="User Management" to="/userManagement" icon={<ManageAccountsIcon />} selected={selected} setSelected={setSelected} />

                        <Item title="Business" to="/business" icon={<BusinessIcon />} selected={selected} setSelected={setSelected} />

                        <Item title="Settings" to="/settings" icon={<TuneIcon />} selected={selected} setSelected={setSelected} />
                        </>
                    )}
                </Box>
            </Menu>
        </ProSidebar>
    </Box>
	);
};

export default Sidebar;