import React, {useEffect} from "react";
import { Box, Alert } from "@mui/material";
import CustomersTable from "../tables/customerList";
import CustomerRecievablesList from "../tables/customerRecievablesList";
import CustomerFeedbackList from "../tables/customerFeedbackList";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTab } from "state";
import CustomTab from "components/Tabs/Tab";

const CustomerTab = () => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(useSelector((state)=> state.currentTab['customerTab']) );
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setCurrentTab({ name: "customerTab", tab: newValue }));
  };
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isFailed, setIsFailed]  = React.useState(false);
  
  const tabComponents = [
    {Component: <CustomersTable setIsSuccess={setIsSuccess}/>, label: "CUSTOMER LIST", "index": 0},
    {Component: <CustomerRecievablesList setIsSuccess={setIsSuccess}/>, label: "CUSTOMER BALANCES", "index": 1},
    {Component: <CustomerFeedbackList setIsSuccess={setIsSuccess}/>, label: "CUSTOMER FEEDBACK", "index": 2},
  ] 

  useEffect(() =>{
    const timer = setTimeout(() => {
      setIsSuccess(false)
      setIsFailed(false)
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);


  return (
    <Box>
      {
        isSuccess ? ( 
            <Alert sx={{ m:"15px" }} variant="filled" severity="success">Operation was successful!</Alert>
        ) : isFailed ? (
            <Alert sx={{ m:"15px" }} variant="filled" severity="error">Operation was not successful!</Alert>
        ) : null
                                        
      }
              
      <CustomTab tabComponents={tabComponents} value={value} handleChange={handleChange}/>
    </Box>
  );
};

export default CustomerTab;